<template>
  <div class="container-fluid main-color">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box ">
          <h4 class="page-title">Add Assessment Form</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form>
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="name">
                    Name
                    <span class="text-danger">*</span>
                  </label>
                </div>

                <div class="col-lg-6">
                  <input
                    name="name"
                    id="name"
                    type="text"
                    class="form-control"
                    :class="{
                      'p-invalid': this.v$.name.$error,
                    }"
                    v-model="name"
                  />

                  <v-errors
                    :vuelidateErrors="{
                      errors: this.v$.name.$errors,
                      value: 'Name',
                    }"
                  ></v-errors>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mx-auto">
                  <div class="mt-3 mb-3 ms-3 text-center">
                    <router-link :to="{ name: 'assessment-form' }">
                      <button type="button" class="btn w-sm btn-secondary me-5">
                        Cancel
                      </button>
                    </router-link>

                    <button
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3"
                      @click="store()"
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>

                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import validationErrors from "../../../mixins/validationErrors";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },

  mixins: [validationErrors],

  data() {
    return {
      name: "",
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  validations() {
    return {
        name: { required },
    };
  },

  methods: {
    async store() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        return;
      }
      this.isLoading = true;

      let sendData = { 
        name: this.name,
        status: 1
       };

      await axios
        .post(`${this.baseUrl}admin/v2/assessment-form`, sendData)

        .then(() => {
          this.isLoading = false;
          this.$router.push({ name: "assessment-form" });
          this.toast.success("Successfully Assessment Form!");
        })
        .catch(() => {
          this.isLoading = false;
          this.toast.error("Something Went Wrong!");
        });
    },
  },

};
</script>
